<template>
  <div class="title-wrapper">
    <div class="title">{{ title }}</div>
    <slot />
    <div class="extra">
      <slot name="extra" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
<style lang="less" scoped>
.title-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #f0f0f0;
}
.title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  position: relative;
  left: 16px;
  margin-right: 32px;
}
.title::before {
  content: "";
  position: absolute;
  top: 4px;
  left: -12px;
  width: 4px;
  height: 18px;
  background: #1890ff;
}
.extra {
  margin-left: auto;
}
</style>