<template>
  <div>
    <Pane />

    <div class="container">
      <div class="wrapper">
        <List />
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import List from "./list";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [watermark],

  components: {
    List
  },

  data() {
    return {
      id: null
    };
  },

  computed: {
    ...mapState("projectDetail", ["project"])
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.setId(id);
      this.fetchProject(); // 获取项目详情
      this.fetchPhaseList(); // 获取阶段列表
      this.fetchPhaseStatistic(); // 获取所有阶段的产值分配情况
    }
  },

  methods: {
    ...mapMutations("projectDetail", ["setId"]),
    ...mapActions("projectDetail", [
      "fetchProject",
      "fetchPhaseList",
      "fetchPhaseStatistic"
    ])
  }
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 0;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
}

.breadcrumb {
  margin-bottom: 12px;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  & > div {
    background-color: #fff;
  }

  .wrapper {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .grid-4 {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
</style>