<template>
  <div>
    <Title title="院所分配详情">
      <template v-slot:extra>
        <div>
          <a-radio-group v-model="selected" button-style="solid">
            <a-radio-button
              v-for="(item, index) in phaseStatistic"
              :key="index"
              :value="index"
            >
              {{ item.progressList[0] ? item.progressList[0].phaseName : "" }}
            </a-radio-button>
          </a-radio-group>
        </div>
      </template>
    </Title>

    <div style="padding: 12px">
      <Phase :item="active" />
    </div>
  </div>
</template>

<script>
import Title from "../title";
import Phase from "./phase";
import { mapState } from "vuex";
export default {
  components: {
    Phase,
    Title,
  },

  computed: {
    ...mapState("projectDetail", ["phaseStatistic"]),

    active() {
      const item = this.phaseStatistic[this.selected];
      return item ? item : {};
    },
  },

  data() {
    return {
      selected: -1,
    };
  },

  mounted() {
    this.selected = this.phaseStatistic.length > 0 ? 0 : -1;
  },

  watch: {
    phaseStatistic() {
      this.selected = this.phaseStatistic.length > 0 ? 0 : -1;
    },
  },
};
</script>